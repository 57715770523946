
import React from 'react'

function WhatWeDo() {
  return (
    <div id='what' className='flex mt-[5rem] lg:mt-0 lg:mb-4 h-full flex-col justify-center items-center'>


      {/* SECTION 1 */}
      <div className='h-full mb-[6rem] lg:mb-0 flex flex-col-reverse lg:flex-row w-full lg:justify-between   '>
        <div className='md:w-[60%] w-100 flex justify-center items-center px-[2rem] mt-6 lg:mt-0'>
          <div data-aos='zoom-out' className='text-[15px] w-full md:w-[70%]'>
            Our unwavering commitment to precision and care makes us your go-to partner for all your diagnostic needs.
            With a rich tapestry of cutting-edge technologies and a compassionate approach,
            we are dedicated to enhancing the health and happiness of your animal companions.
          </div>

        </div>
        <div data-aos='flip-left' className='w-full  lg:w-[47%] flex justify-center items-center object-cover'>
          <img
            src='https://res.cloudinary.com/dwjgb0skl/image/upload/v1693662786/my-upload/timi%20own/FV3LWNQ5338qPLQTc6UuLiDXcx_a5RRwxRSWFhQGz74__plaintext_638288870722758261_gl2axu.jpg'
            objectFit="cover"
            width='400'
            height='350'
            alt='np-img'
          />
        </div>
      </div>

      {/* SECTION 2 */}
      <div className='h-full lg:h-[50vh] lg:mb-0 flex flex-col lg:flex-row w-full lg:justify-between '>
        <div data-aos='flip-right' className='w-full lg:w-[47%] flex justify-center items-center object-cover'>
          <img
            src='https://res.cloudinary.com/dwjgb0skl/image/upload/v1692900439/my-upload/timi%20own/phram_1_lstzpq.jpg'
            objectFit="cover"
            width='435'
            height='400' 
            alt='np-img'
          />
        </div>
        <div className='md:w-[60%] w-100 flex justify-center items-center px-[2rem] mt-6 lg:mt-0' >
          <div data-aos='zoom-out' className='text-[15px] w-full lg:px-0 md:w-[70%]'>
            As ardent advocates for animal health, we believe in sharing knowledge and fostering a community.
            Immerse yourself in our comprehensive resources, articles, and blog posts that cover a plethora of topics relevant to veterinary care,
            diagnostics, and overall pet wellness.
          </div>
        </div>

      </div>

      {/* SECTION 3 */}
      <div className='h-full mb-[4rem] lg:mb-0  flex flex-col-reverse lg:flex-row w-full lg:justify-between   '>
        <div className='md:w-[60%] w-100 flex justify-center items-center mt-6 px-[2rem]'>
          <div data-aos='zoom-in' className='text-[15px] w-full  md:w-[70%]'>
            We pride ourselves on a user-centric experience,
            ensuring that you're equipped with all the knowledge you need to make informed decisions about your furry friend's health.
          </div>
        </div>
        <div data-aos='flip-left' className='w-full mt-[4rem] lg:w-[47%] object-cover'>
          <img
            src='https://res.cloudinary.com/dwjgb0skl/image/upload/v1693662760/my-upload/timi%20own/IMG-20230829-WA0009_kdgllp.jpg'
            objectFit="cover"
            width='500'
            height='500'
            alt='np-img'
          />
        </div>
      </div>
    </div>
  )
}

export default WhatWeDo