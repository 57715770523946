import React from 'react'
import ham from '../assets/images/menu-icon.svg'
import Items from './Items'
import logo from '../assets/images/logo.svg'


function Nav({ openSideDrawer }) {
  return (
    <div className="lg:h-[80px] h-[64px]  w-[100%] border-[0.5px] bg-white fixed z-10 top-0 border-b-[#dedddd]  flex pl-4 pr-6 lg:px-[124px] items-center justify-between">
      <div className="flex items-center">
        <a href="/">
        
          <img width='170' src={logo} className="mr-8 cursor-pointer" alt="logo" />
        </a>

      </div>
      <div className="flex items-center">

        <div className="hidden md:flex">
          <Items />
        </div>
        <div className="lg:hidden" onClick={openSideDrawer}>
          <img src={ham} className='bg-black text-black' alt="Hamburger menu icon" />
        </div>
      </div>
    </div>
  )
}

export default Nav