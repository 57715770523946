
import { useEffect, useState } from 'react';
import './App.css';
import 'aos/dist/aos.css';
import Nav from './components/Nav';
import AOS from 'aos';
import SideNav from './components/SideNav';
import Products from './components/Products';
import WhatWeDo from './components/WhatWeDo';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import ContactUs from './components/ContactUs';

function App() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const [showSideDrawer, setSideDrawer] = useState(false);

  const sideDrawerCloseHandler = () => {
    setSideDrawer(false);
  };
  const sideDrawerToggleHandler = () => {
    setSideDrawer(!showSideDrawer);
  };

  return (
    <div>
      <div>
        <Nav openSideDrawer={sideDrawerToggleHandler}/>
        <SideNav open={showSideDrawer} closed={sideDrawerCloseHandler} />
      </div>
      <Header/>
      <Products/>
      <WhatWeDo/>
      <AboutUs/>
      <ContactUs/>
      <Footer/>
    </div>
  );
}

export default App;
