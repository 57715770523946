


function Items() {
  return (
    <div className="flex-col gap-8 md:gap-0 flex md:flex-row md:flex">
      <div className="">
        <a href='#products'>
          <span className="font-bold flex items-center peer  cursor-pointer text-[20px] lg:text-sm text-primary px-8">
            Our services
            <span className="ml-2">
              {/* <Image src={vector} alt=""/> */}
            </span>
          </span></a>
      </div>

      <a href="#what">
        <span className="font-bold text-[20px] lg:text-sm cursor-pointer text-primary px-8">
          What we do
        </span>
      </a>
      <a href="#about">
        <span className="font-bold text-[20px] lg:text-sm cursor-pointer text-primary px-8">
          About us
        </span>
      </a>
      <a href="#contact">
  
        <span className="font-bold text-[20px] lg:text-sm cursor-pointer text-primary px-8">
          Conatct-us
        </span>
      </a>
    </div>
  );
}

export default Items;
