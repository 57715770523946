import React from 'react'

// import linkedin from '../assets/images/linkedin.svg'
// import facebook from '../assets/images/facebook.svg'
// import twitter from '../assets/images/twitter.svg'
// import insta from '../assets/images/insta.svg'

import whatsapp from '../assets/images/whatsapp1.svg'
import logo from '../assets/images/logo2.svg'


export default function Footer() {
  // let number = '080333454553'
  // let whatsapps = "https://wa.me/+2348063684886/?text=Hello%20Lanchak.%20I'm%20enquiring%20about"
  return (
    <>
      <div  className="lg:h-[320px] mt-16 px-4 lg:px-0 bg-gradient-to-r from-grad to-primary  font-sans text-white">
        <div className="lg:w-[80%] w-full mx-auto">
          <div className="gap-5 lg:flex lg:flex-row flex-col justify-center pt-10 lg:pt-[50px] lg:mb-[50px] flex">
            <span className="lg:flex-[0.8] ">
              <div className='mb-6'>
                <img width='170' src={logo} className="mr-8  cursor-pointer" alt="logo" />
              </div>
              <div className="font-sans pt-4 lg:pt-0 text-sm font-thin mb-5">
                Thank you for choosing Lanchak Company Limited as your partner in veterinary diagnostics. With us, you embark on a voyage to ensure a vibrant life for your cherished companions. Together, let's sculpt a world where wagging tails and contented purrs resonate with robust health and boundless joy.
              </div>
            </span>
            {/* <span className=" flex gap-8 lg:gap-0 justify-center flex-wrap lg:flex-[1.4] ">
              <ol className="w-[40%] lg:w-[55%] flex flex-col lg:items-center" >
                <li className="font-sans font-bold text-base mb-5">Site Map</li>
                <Link href=''></Link> <li className="font-sans text-[13px] mb-3 font-thin">Home</li>
                <Link href=''></Link> <li className="font-sans text-[13px] mb-3 font-thin">Services</li>
                <Link href=''></Link> <li className="font-sans text-[13px] mb-3 font-thin">About us</li>
                <Link href=''></Link> <li className="font-sans text-[13px] mb-3 font-thin">What we do</li>

              </ol>
              <ol className="w-[45%] lg:w-[45%]">
                <li className="font-sans font-bold text-base mb-5">Contact</li>
                <Link href={'tel:' + number}> <li className="font-sans text-[13px] mb-3 font-thin"> 08063684886</li></Link>
                <li className="font-sans text-[13px] mb-3 font-thin"> 4, Gbadebo Osoba Street, Monijesu Bus Stop, Adigbe Road, Abeokuta,Ogun State, Nigeria.</li>
                <li className="font-sans text-[13px] mb-3 font-thin" onClick={() => (window.location = `mailto:help@lanchak.com`)}>help@lanchak.com</li>
              </ol>
            </span> */}
          </div>
          <div className="h-[.1px] mt-10 lg:mt-0 bg-white"></div>
          <div className="mt-4">
            <div className="flex mt-6 pb-3 lg:pb-3 lg:my-0 px-4 lg:px-0 justify-center items-center">
              <span className="font-sans flex text-center text-[10px] lg:text-sm">© 2023 Lanchak Company Limited. All rights reserved</span>
            </div>
            {/* <div className="gap-5 items-center pb-2 lg:p-0 lg:justify-end justify-center flex">
            <Image className="cursor-pointer" src={linkedin} alt="icon" />
            <Image className="cursor-pointer" src={facebook} alt="icon" />
            <Image className="cursor-pointer" src={insta} alt="icon" />
            <Image className="cursor-pointer" src={twitter} alt="icon" />
          </div> */}
          </div>
        </div>

      </div>

      <div className='fixed rounded-[50%] text-black lg:w-[6rem] lg:h-[6rem] bottom-3 right-5'>
        <a href='/'>
          <img src={whatsapp} width='80'  className='w-[50px] lg:w-[70px]' alt='no img' /></a>
      </div>
    </>
  )
}
