import React from 'react'
import header from '../assets/images/bigimg.jpg'
 
function Header() {
  return (
    <div  className=' h-[88vh] lg:h-[90vh] overflow-hidden relative   text-white'>
      <img src={header} data-aos='fade-down' height='200px' width='300px' className='bg-cover w-full mt-[4rem] lg:mt-0' objectFit='cover' alt='no'/>
      <div data-aos='fade-up'  data-aos-easing="linear" data-aos-duration="1500" className='absolute bg-slate-200 lg:bg-white lg:bg-opacity-80 rounded-br-[10rem] lg:top-[34%] lg:left-[60%] '>
        <div  className=' h-[20rem] w-[100%] lg:h-[20rem] lg:w-[30rem] px-9 font-sans py-5 text-black '>
           <div className='lg:text-[40px] text-[30px] font-[800] text-primary mb-3'>
              Welcome to Lanchak Company Limited
           </div>
           <div className='text-sm  font-normal mb-4 pb-4 lg:pb-0'>
           A trailblazer in advanced veterinary diagnostics. Our unwavering commitment to precision and care makes us your go-to partner for all your diagnostic needs.
           </div>
           <a href="#products">
           <div className='w-[10rem] h-[2.5rem] cursor-pointer text-white font-sans rounded flex justify-center bg-primary items-center'>
            Our Services
           </div></a>
           
        </div>
      </div>
    </div>
  )
}

export default Header