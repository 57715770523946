import React from 'react'
import Backdrop from './BackDrop';
import logo from '../assets/images/logo.svg'
import Items from './Items';

function SideNav({open, closed}) {
  return (
    <>
      <Backdrop show={open} clicked={closed} />
      <div
        className={`fixed w-[80%]  h-full top-0 left-0 right-0 bg-white px-5 py-8 z-50 shadow-small transition-transform duration-300 ease-out lg:hidden ${
          open ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <img src={logo} height="80" alt=' no img'/> 
        <div className='lg:hidden p-0 mt-8'>
          <Items/>
        </div>
      </div>
    </>
  );
}

export default SideNav