
import React from 'react'
 import right from '../assets/images/rightt.png'

function Products() {
  const tabs = [
    {
      title: "Post Mortem Examination "
    },
    // {
    //   title: "Antimicrobial Sensitivity Test"
    // },
    // {
    //   title: "Baterial/Fungi Culture"
    // },
    {
      title: "Faecal Analysis"
    },
    {
      title: "Feed Microbial Test"
    },
    {
      title: "Water Microbial Test"
    },
    {
      title: "Water Chemistry Analysis      "
    },
    {
      title: "Minimum Inhibitory Concentration      "
    },
    {
      title: "Quantitative Aflatoxin Tests      "
    },
    {
      title: "Infectious Bronchitis (IB) Elisa "
    },
    {
      title: "Mycoplasma gallisepticum (MG) Elisa"
    },
    {
      title: "Infectious Bursal Disease (IBD) Elisa      "
    },
    {
      title: "Newcastle Disease HI/HA sero-monitoring Test"
    },
    {
      title: "Vaccine Potency Test      "
    },

    {
      title: "Day-old-chicks Screening      "
    },
    {
      title: " hygiene Aduit    "
    },
    {
      title: "Hygiene Audit      "
    },
    {
      title: "Meat Quality Test      "
    },
    {
      title: "Parvovirus Screening      "
    },
    {
      title: "Cook’s test      "
    },
    {
      title: "Full Blood Count (FBC)/Haematology      "
    },
    {
      title: "Haemoparasite Screening      "
    },
    {
      title: "Packed cell volume (PCV)      "
    },
    // {
    //   title: "Vaccinations      "
    // },
    {
      title: "Professional Services and Consultancy"
    },


  ]
  return (
    <div id='products' className='flex flex-col mb-16 h-full mt-[2rem] lg:mt-0 justify-center items-center'>
      <div data-aos='fade-down' className='text-primary text-[26px] h-full font-bold lg:mt-[6.5rem] mb-3'>
        Our Services
      </div>
      <div data-aos='fade-up' className='mb-2 font-medium text-center'>
        Nurturing Health, Embracing Happiness: Our Veterinary Promise.
      </div>
      <div data-aos='flip-right' className='h-[2px] mb-4 w-12 bg-primary'>

      </div>
      <div className='flex flex-wrap gap-6 mt-5 justify-center'>
        {
          tabs.map(item => (
            <div data-aos='zoom-in-up' key={item} className=' w-[90%] lg:w-[25rem]  gap-4 flex justify-start items-center'>
              <div className=' '>
                <img src={right} width='20' height='20' className='text-primary' objectFit='cover' alt='no img' />
              </div>
              <div className=' text-sm'>
                {item.title}
              </div>
               
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Products