import React from 'react'
import drugs from '../assets/images/drugs.png'
import heart from '../assets/images/heart-rate.png'
import cock from '../assets/images/cock.png'

function AboutUs() {
    return (
        <div id='about' className='flex flex-col  bg-slate-100 lg:h-[90vh] pb-[6rem] justify-center items-center'>
            <div className='mb-[3rem] flex items-center flex-col'>
                <div data-aos='zoom-in-down' className='text-primary  text-[26px] font-bold mt-[6.5rem] mb-3'>
                    About Us
                </div>
                <div data-aos='zoom-in-up' className='mb-2 font-semibold text-center'>
                    Nurturing Health, Embracing Happiness: Our Veterinary Promise.
                </div>
                <div data-aos='flip-left' className='h-[2px] w-12 bg-primary'>

                </div>
            </div>


            <div className='flex justify-center flex-wrap gap-9'>
                <div data-aos='flip-left' className='w-[90%]  lg:w-[24rem] px-10 py-10 flex flex-col justify-center items-center shadow-lg '>
                    <div>
                        <img src={drugs} alt='no img' width='50' height='50' />
                    </div>
                    <div className='text-sm font-sans mt-8 '>
                        <div className='mb-4'>
                            Lanchak Company Limited stands at the crossroads of innovation and compassion,
                            offering a spectrum of diagnostic solutions that cater to the unique needs of veterinarians,
                            livestock, pet owners, and animal enthusiasts.
                        </div>
                        <div>
                            Our journey is fueled by a passion for excellence,
                            and our team of accomplished veterinarians, technicians, and researchers leaves no stone unturned to provide you
                            with accurate and timely diagnoses.

                        </div>

                    </div>
                </div>

                <div data-aos='flip-right' className='w-[90%] lg:w-[24rem] px-10 py-10 flex flex-col justify-center items-center shadow-lg '>
                    <div>
                        <img src={heart} alt='no img' width='50' height='50' />
                    </div>
                    <div className='text-sm font-sans mt-8  '>
                        <div className='mb-4'>
                            Our understanding of the profound bond between animals and humans drives our mission to offer the highest quality care.
                        </div>
                        <div className='mb-2'>
                            Our state-of-the-art facilities are a testament to our commitment to your pet's well-being,
                            whether it's a routine health assessment or a complex medical inquiry.
                        </div>
                        <div>
                            By leveraging the latest advancements in veterinary medicine, we empower you with insights that make a real difference.
                        </div>

                    </div>
                </div>

                <div data-aos='flip-left' className='w-[90%] lg:w-[24rem] px-10 py-10 flex flex-col justify-center items-center shadow-lg '>
                    <div>
                        <img src={cock} alt='no img' width='50' height='50' />
                    </div>
                    <div className='text-sm font-sans mt-4'>
                       <div className='mb-2'>
                        As ardent advocates for animal health, we believe in sharing knowledge and fostering a community.
                       </div> 
                       <div className='mb-2'>
                         Immerse yourself in our comprehensive resources, articles, and blog posts that cover a plethora of topics relevant to veterinary care,
                        diagnostics, and overall pet wellness.
                       </div>
                       <div >
                       Whether you're an experienced pet owner, a veterinary professional,
                        or someone who cherishes all creatures great and small, our platform invites you to expand your understanding.
                       </div>
                        


                    </div>
                </div>

            </div>
        </div>
    )
}

export default AboutUs