
import React, { useState, useEffect } from 'react'


function ContactUs() {

  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);


  useEffect(() => {
    const geolocationAPI = navigator.geolocation;

    const getUserCoordinates = () => {
      if (!geolocationAPI) {

      } else {
        geolocationAPI.getCurrentPosition((position) => {
          const { coords } = position;
          setLat(coords.latitude);
          setLong(coords.longitude);
        }, (error) => {

        })
      }
    }
    getUserCoordinates();
  }, [])


const [load, setLoad] = useState(false)





  //   const apiURL = 'https://ipgeolocation.abstractapi.com/v1/'
  //   const apiKey = '1c5f9b11df5a4776bf633d238396ee3a';

  //     const getUserLocationFromAPI = () => {
  //      try {
  //        const response =  axios.get(apiURL, {api_key: apiKey});
  //        console.log(response.data);
  //      } catch (error) {
  //        setError('Something went wrong getting Geolocation from API!')
  //      }
  //    }
  // getUserLocationFromAPI()
  return (

    <div id='contact' className='flex flex-col lg:mt-[4rem] lg:h-[90vh] pb-[6rem] justify-center items-center'>

      <div className='mb-[3rem] flex items-center flex-col'>
        <div data-aos='zoom-out' className='text-primary  text-[26px] font-bold mt-[6.5rem] mb-3'>
          Get in Touch
        </div>
        <div data-aos='zoom-in' className='mb-2 font-medium'>
          Send us a message
        </div>
        <div data-aos='zoom-out' className='h-[2px] w-12 bg-primary'>

        </div>
      </div>
      <form
        className='flex flex-col gap-5 justify-center items-center'
        action="https://formsubmit.co/help@lanchak.com"
        method="POST"
      >
        <div data-aos='zoom-in-down' className='flex lg:flex-row gap-7  flex-col'>
          <input
            type="text"
            name="Full Name"
            placeholder="Full Name"
            required
            className='rounded-lg border-2 border-slate-300 px-3 w-[20rem] h-[2.3rem]'
          />
          <input
            type="number"
            name="Number"
            placeholder="Phone Number"
            required
            className='rounded-lg border-2 border-slate-300 px-3 w-[20rem] h-[2.3rem]'
          />
        </div>
        <div data-aos='zoom-in-down' className='flex lg:flex-row gap-7  flex-col'>
          <input
            type="text"
            name="Name of Farm"
            placeholder="Name of Farm"
            required
            className='rounded-lg border-2 border-slate-300 px-3 w-[20rem] h-[2.3rem]'
          />
          <input
            type="text"
            name="Local Government"
            placeholder="Local government"
            required
            className='rounded-lg border-2 border-slate-300 px-3 w-[20rem] h-[2.3rem]'
          />
        </div>
        <div data-aos='zoom-in-down' className='flex lg:flex-row gap-7 flex-col'>
          <input
            type="text"
            name="State"
            placeholder="State"
            required
            className='rounded-lg border-2 border-slate-300 px-3 w-[20rem] h-[2.3rem]'
          />
          <input
            type="text"
            name="Types of Animals"
            placeholder="Types of animals"
            required
            className='rounded-lg border-2 border-slate-300 px-3 w-[20rem] h-[2.3rem]'
          />
        </div>
        <div data-aos='flip-right' className='flex lg:flex-row gap-7 flex-col' >
          <input
            type="number"
            name="Population"
            placeholder="Size or Population"
            required
            className='rounded-lg border-2 border-slate-300 px-3 w-[20rem] h-[2.3rem]'
          />
          <input
            type="email"
            name="Email"
            placeholder="Email"
            required
            className='rounded-lg border-2 border-slate-300 px-3 w-[20rem] h-[2.3rem]'
          />
        </div>
        <input
          type="number"
          name="Longtitude"
          placeholder="Email"
          value={long}
          required
          className='rounded-lg hidden border-2 border-slate-300 px-3 w-[20rem] h-[2.3rem]'
        />
        <input
          type="number"
          name="Latitude"
          placeholder="Email"
          value={lat}
          required
          className='rounded-lg border-2 hidden border-slate-300 px-3 w-[20rem] h-[2.3rem]'
        />
        <textarea
          data-aos='zoom-in-up'
          row="8"
          cols='50'
          type="message"
          name="message"
          placeholder="Leave a message"
          required
          className='rounded-lg border-2 w-[80%] lg:w-[100%] border-slate-300 px-3  h-[5rem]'
        />

        <input type="hidden" name="_template" value="table" />
        <button type="submit" data-aos='zoom-out' onClick={()=> setLoad(true)} className="bg-gradient-to-r from-primary rounded-md w-[15rem] px-3 h-[2.5rem] to-grad  text-white">
          {load?'loading...': "send"}
        </button>
      </form>
    </div>

  )
}

export default ContactUs